<template>
  <div class="progress-indicator">
    <p
      v-for="(i, index) in circlesAmount"
      :key="i"
      :class="['progress-indicator__circle', { 'progress-indicator__circle--selected': selectedItem === index }]"
    />
  </div>
</template>

<script lang="ts">
export default defineNuxtComponent({
  props: {
    amount: {
      type: Number,
      required: true,
    },
    selectedItem: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const MAX_CIRCLES_AMOUNT = 6
    const circlesAmount = computed(() => (props.amount > MAX_CIRCLES_AMOUNT - 1 ? MAX_CIRCLES_AMOUNT : props.amount))

    return {
      circlesAmount,
    }
  },
})
</script>

<style lang="scss" scoped>
.progress-indicator {
  @apply inline-flex;
  @apply justify-between;
  @apply items-center;
  @apply px-4;
  @apply py-2;
  @apply bg-white;
  @apply rounded-xl;

  z-index: 4;

  &__circle {
    height: 7px;
    width: 7px;
    @apply bg-spl-gray-4;
    @apply rounded-full;
    @apply mx-1;
    @apply transition-all duration-200 ease-in-out;

    &--selected {
      @apply bg-spl-dark;
    }

    &:nth-child(6) {
      height: 5px;
      width: 5px;
      @apply bg-spl-gray-2;
    }
  }
}
</style>
